// 公告
import { post } from '@/http/http'

// 公告查询
export const msgQuery = p => post('api/v1.0/noticeinfo/noticeinfo_get', p)

// 公告顺序
export const msgSort = p => post('api/v1.0/noticeinfo/notice_drop_get', p)

// 公告添加
export const msgAdd = p => post('api/v1.0/noticeinfo/noticeinfo_add', p)

// 公告编辑
export const msgEdit = p => post('api/v1.0/noticeinfo/noticeinfo_update', p)

// 公告删除
export const msgDelete = p => post('api/v1.0/noticeinfo/noticeinfo_delete', p)

// 公告移动
export const msgMove = p => post('api/v1.0/noticeinfo/notice_oper', p)
