<template>
<!-- 公告管理 -->
  <div class="box100">
    <div class="whiteBg">
      <template v-if="roleBtn[0].power">
        <div class="el-icon-plus settingAdd" @click="noticeAdd"><span> 新增</span></div>
      </template>
      <div class="table_list" style="margin-top: 16px;">
        <el-table
          :header-cell-style="{background:'#EBF2FE'}"
          :data="msgList">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="名称"
            prop="noticename">
          </el-table-column>
          <el-table-column
            label="内容"
            prop="noticemark"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="添加链接"
            prop="noticeurl">
          </el-table-column>
          <el-table-column
            label="排序">
            <template slot-scope="scope" v-if="roleBtn[3].power">
              <el-button @click="sortHand(scope.row, 1)" type="primary" size="mini" plain
                 :disabled="scope.$index === 0" style="display: block">
                上移<i class="el-icon-caret-top el-icon--right"></i>
              </el-button>
              <el-button @click="sortHand(scope.row, 2)" type="primary" size="mini" plain
                 :disabled="scope.$index === msgList.length-1" style="display: block;margin-left:0px;margin-top: 5px;">
                下移<i class="el-icon-caret-bottom el-icon--right"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="操作">
            <template slot-scope="scope">
              <template v-if="roleBtn[2].power">
                <el-button type="text" size="small" @click="showEdit(scope.row)">编辑</el-button>
              </template>
              <template v-if="roleBtn[1].power">
                <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>

    <!--  【新增-编辑】  -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="msgfrom" :rules="formRules" ref="notice">
        <el-form-item label="公告名称" prop="noticename">
          <el-input v-model="msgfrom.noticename"  placeholder="请输入公告名称"></el-input>
        </el-form-item>
        <el-form-item label="公告内容" prop="noticemark" class="fiText" style="margin-bottom: 18px;">
          <el-input class="formTextarea" v-model="msgfrom.noticemark" type="textarea" maxlength="150" placeholder="请输入公告内容"></el-input>
        </el-form-item>
        <el-form-item label="添加链接" prop="noticeurl">
          <el-input v-model="msgfrom.noticeurl"  placeholder="请输入链接"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { msgQuery, msgAdd, msgEdit, msgDelete, msgMove } from '@/api/msg'
export default {
  props: ['roleBtn'],
  data () {
    return {
      msgWord: '',
      page: 1,
      size: 10,
      total: 0,
      msgList: [],
      fromShow: false,
      msgfrom: {
        noticename: '',
        noticemark: '',
        noticeurl: '',
        noticeid: ''
      },
      formRules: {
        noticename: [
          { required: true, trigger: 'blur', message: '公告名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        noticemark: [
          { required: true, trigger: 'blur', message: '公告内容不能为空' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
        ]
      },
      dialogName: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size
      }
      msgQuery(params).then(res => {
        if (res.status === 1) {
          this.msgList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 新增展示
    noticeAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.$nextTick(() => {
        this.$refs.notice.resetFields()
        this.msgfrom = {}
      })
    },
    showEdit (row) {
      this.msgfrom = { ...row }
      this.fromShow = true
      this.dialogName = '编辑'
    },
    // [新增-编辑]提交
    FromConfirm () {
      this.$refs.notice.validate((valid) => {
        if (!valid) return
        const params = {
          noticeid: this.msgfrom.noticeid,
          noticename: this.msgfrom.noticename,
          noticemark: this.msgfrom.noticemark,
          noticeurl: this.msgfrom.noticeurl
        }
        if (this.dialogName === '新增') {
          msgAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.dialogName === '编辑') {
          msgEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 排序移动
    sortHand (row, type) {
      msgMove({ noticeid: row.noticeid, oper: type }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    FromCancel () {
      this.$refs.notice.resetFields()
      this.fromShow = false
    },
    // 删除
    handDel (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        msgDelete({ noticeid: row.noticeid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.fiText >>> .el-form-item__content{
  line-height: 16px;
}
</style>
